/* App.css */

body {
  font-family: 'Arial', sans-serif;
  background-color: #f7f7f7;
  margin: 0;
  padding: 0;
}

.flashcard-container {
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.generate-button {
  background-color: #4caf50;
  color: #fff;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.generate-button:hover {
  background-color: #45a049;
}

.loading-container {
  text-align: center;
  margin-top: 20px;
}

.loader {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

.flashcard-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;
}

.flashcard-box {
  width: 48%;
  margin-bottom: 20px;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.pagination {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

button {
  background-color: #3498db;
  color: #fff;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

button:disabled {
  background-color: #a0a0a0;
  cursor: not-allowed;
}

button:hover {
  background-color: #2980b9;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* App.css */

.footer {
  margin-top: 20px;
  padding: 10px;
  background-color: #bfaeae;
  text-align: center;
  font-size: 14px;
  color: #333;
}

.file-input-container {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.error-message {
  color: red;
  margin-top: 5px; /* Add some space above the error message */
}

.flashcard-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.flashcard-container h1 {
  margin-bottom: 20px; /* Adjust the margin as needed */
}

/* Apply animation to the text */
.flashcard-container p {
  animation: fadeIn 1s ease-in-out; /* You can customize the animation */
}

.flashcard-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Center the flashcards horizontally */
  margin-top: 20px;
}

.flashcard-box {
  width: 48%;
  margin-bottom: 20px;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.footer {
  margin-top: 20px;
  padding: 10px;
  background-color: #bfaeae;
  text-align: center;
  font-size: 14px;
  color: #333;
  width: 100%; /* Add this line to make the footer span the full width */
}

.custom-file-input {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.custom-file-input input {
  display: none; /* Hide the default input */
}

.custom-file-input label {
  background-color: #4caf50;
  color: #fff;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
  margin-right: 10px; /* Adjust margin as needed */
}

.custom-file-input label:hover {
  background-color: #45a049;
}

/* Add animation to the input text */
.custom-file-input span {
  display: inline-block;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.custom-file-input input:checked + label span {
  opacity: 1;
  transform: translateY(0);
}

.partition {
  height: 1px;
  background-color: #ccc; /* Adjust the color as needed */
  margin: 10px 0; /* Adjust the margin as needed */
}

.textarea-description {
  width: 1.5 times;
  height: double height;
}

.bigger-text {
  font-size: 18px; /* Adjust the size as needed */
  /* Add any other styling you desire */
}


@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
